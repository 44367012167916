import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { Dialog, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgreements,
  getSingleProposal,
  getStrategies,
} from "../../../redux/apiCalls";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { userRequest } from "../../../requestMethods";
import toast from "react-hot-toast";

const StrategyAlert = ({ open, setOpen, handleGeneratePdf }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedStrategy, setSelectedStrategy] = useState();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    agreements,
    loading: agreementsLoading,
    error: agreementsError,
  } = useSelector((state) => state.agreement);
  const {
    strategies,
    loading: strategiesLoading,
    error: strategiesError,
  } = useSelector((state) => state.strategy);
  const { proposalId } = useParams();
  const { proposal } = useSelector((state) => state.proposals);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      getAgreements(dispatch);
      getStrategies(dispatch);
    }
  }, [proposalId, open, dispatch]);
  const connectStrategy = async () => {
    try {
      if (!selectedStrategy) {
        toast.error("Please select a strategy");
        return;
      }

      const body = {
        signedDate: proposal?.signedDate,
        agreementId: agreements?.[0]?._id,
        userIP: "0.0.0.0",
        strategyId: selectedStrategy,
      };

      const response = await userRequest().put(
        `/proposals/sign/${proposalId}`,
        body
      );
      if (response.status === 200) {
        const response = await dispatch(getSingleProposal(proposalId));
        handleGeneratePdf(response);
        handleClose();
        // toast.success(response.
      }
    } catch (error) {
      console.error("Failed to sign the proposal:", error);
    }
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Strategy Not connected!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: "1.5rem" }}>
          Please connect a strategy image before generating the PDF
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="strategy-select-label">Select a strategy</InputLabel>
          <Select
            labelId="strategy-select-label"
            id="strategy-select-select"
            value={selectedStrategy}
            label="Select a strategy"
            onChange={(e) => setSelectedStrategy(e.target.value)}
          >
            {strategies.map((strategy) => {
              return (
                <MenuItem key={strategy._id} value={strategy._id}>
                  <img
                    src={strategy?.image}
                    width={36}
                    height={36}
                    style={{ marginRight: "1rem" }}
                    alt=""
                  />
                  <span>
                    Pricing Package:&nbsp;
                    {strategy?.pricingPackage?.name}
                  </span>
                  &nbsp; &nbsp;
                  <span
                    style={{
                      fontSize: "12px",
                      color: "white",
                      backgroundColor: "#0891b2",
                      padding: "0px .25rem",
                      borderRadius: "20px",
                    }}
                  >
                    $:&nbsp;
                    {strategy?.pricingPackage?.pricing}
                  </span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          cancel
        </Button>
        <Button variant="contained" autoFocus onClick={connectStrategy}>
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StrategyAlert;
